import {
  required,
  requiredIf,
  minLength,
  maxLength,
  email,
  between,
  sameAs,
  helpers,
  minValue,
  maxValue,
  numeric
} from 'vuelidate/lib/validators'
import { isEmpty } from 'lodash-es'
import { phone } from '@/utils/validation.js'
import { store } from '@/store/index.js'
import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'

const validFileSize = (options = {}) => {
  return helpers.withParams(options, (value) => {
    if (!value) {
      return true
    }
    const fileSizeinKb = value.size / 1024
    return fileSizeinKb <= options.maxFileSizeInKB
  })
}

const validFileType = (options = {}) => {
  return helpers.withParams(options, (value) => {
    if (!value) {
      return true
    }
    const fileName = value.name.toLowerCase()
    return options.allow.some((extension) => fileName.endsWith(extension))
  })
}

export const validNpi = (npi = '') => {
  /*
    Function to validate npi number using Luhn algorithm
    https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
  */
  if (npi.length !== 10 || !/^\d+$/.test(npi)) {
    return false
  }

  //  1. Extract check digit from npi
  const checkDigit = parseInt(npi.slice(-1), 10)
  npi = npi.slice(0, -1)

  //  2. Add validation prefix (80840) to npi
  npi = `80840${npi}`
  const npiNumberList = npi.split('')

  //  3.Double the value of alternate digits, beginning with the rightmost digit.
  //  List is reversed to start from leftmost digit
  npiNumberList.reverse()
  const npiDoubledList = []
  const npiUnaffectedList = []
  npiNumberList.forEach((digit, index) => {
    if (index % 2 === 0) {
      npiDoubledList.push(parseInt(digit, 10) * 2)
    } else {
      npiUnaffectedList.push(parseInt(digit, 10))
    }
  })

  //  4. extract all the individual digits from the doubled list
  const npiDoubledListString = npiDoubledList.map((digit) => digit.toString())
  npiDoubledListString.forEach((digit) => {
    if (digit.length === 2) {
      npiUnaffectedList.push(parseInt(digit[0], 10))
      npiUnaffectedList.push(parseInt(digit[1], 10))
    } else {
      npiUnaffectedList.push(parseInt(digit, 10))
    }
  })
  //  5. Sum up the individual digits of the doubled digits and the unaffected digits.
  let npiSum = npiUnaffectedList.reduce((partialSum, a) => partialSum + a, 0)

  //  6. Subtract the total from the next highest number evenly divisible by 10.
  npiSum = Math.floor((npiSum + 9) / 10) * 10 - npiSum

  //  7. If the result is equal to the check digit, the number is valid.
  return npiSum === checkDigit
}

/* To set a server error message on a field. Can be cleared up by removing from the validation rules */
export const serverError = (message) => {
  return helpers.withParams({ type: 'serverError', message }, (value) => {
    if (!value) {
      return true
    }
    return false
  })
}

/*
 *  This is checking whether the object is part of a list. Be careful using it on large lists.
 *  Make sure the validation is only executed e.g. on blur event, but not per keystroke!
 */
export const validListItem = (options = {}) => {
  return helpers.withParams(options, (value) => {
    if (!value) {
      return true
    }
    return !!options.items.find((item) =>
      options.compare ? options.compare(value, item) : value === item
    )
  })
}

export default class Validation {
  firstName = {
    required,
    maxLength: maxLength(64)
  }

  lastName = {
    required,
    maxLength: maxLength(64)
  }

  gender(exclusions = []) {
    return {
      required,
      notIn: (value) => !exclusions.includes(value)
    }
  }

  guardianRelationship = {
    required
  }

  ethnicity(isRequired = false) {
    return isRequired ? { required } : {}
  }

  race(isRequired = false) {
    return isRequired ? { required } : {}
  }

  collectionLocation = {
    required
  }

  address2 = {
    maxLength: maxLength(256)
  }

  hour = {
    required,
    between: between(1, 12)
  }

  min = {
    required,
    between: between(0, 59)
  }

  ms = {
    required
  }

  dateInPastOrToday = {
    maxValue: maxValue(new Date())
  }

  age(val) {
    return {
      minValue: val ? minValue(val) : false
    }
  }

  password = {
    required,
    minLength: minLength(6),
    maxLength: maxLength(64)
  }

  confirmPass = {
    required,
    sameAsPassword: sameAs('password')
  }

  terms = {
    sameAs: sameAs(() => true)
  }

  kitId(kitIdLength) {
    return {
      required,
      minLength: kitIdLength > 0 ? minLength(kitIdLength) : false
    }
  }

  patientAccessCode = {
    required
  }

  personalAccessCode = {
    required
  }

  eligibilityActivateEmail = {
    required,
    email
  }

  signupAccessCode(hasSignupAccessCode, dtcOrg) {
    // If the signup access code feature is turned OFF, then it is definitely not required
    if (!hasSignupAccessCode) {
      return {
        required: false
      }
    }
    // If the signup access code feature is turned ON, then dtcOrganization is going to be considered.
    // If dtcOrganization is not defined (or null) than the signup access code is required.
    return {
      required: dtcOrg ? false : required
    }
  }

  language = {
    required
  }

  passportIssuedCountry = {
    required: requiredIf((model) => {
      return !isEmpty(model.passportNumber)
    })
  }

  passportNumber = {
    required: requiredIf((model) => {
      return !isEmpty(model.passportIssuedCountry)
    })
  }

  medicalRecordNumber = {
    maxLength: maxLength(32)
  }

  group = {
    maxLength: maxLength(100)
  }

  consentStatus = {
    required
  }

  purchase = {
    required
  }

  insurance = {
    companyName: {
      required
    },
    email: {
      email
    },
    phone: {
      validNumber: phone(getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)).validNumber
    },
    relationship: {
      required
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    groupNumber: {
      required
    },
    plan: {},
    groupEmployerName: {},
    policyNumber: {
      required
    },
    document: {
      validFileSize: validFileSize({ maxFileSizeInKB: 10 * 1024 }),
      validFileType: validFileType({
        allow: ['.png', '.jpg', '.jpeg', '.pdf']
      })
    }
  }

  zip = () => {
    const regex = new RegExp(store.getters?.getConfig(CONFIG.ZIP_PATTERN))
    return {
      required,
      pattern: (value) => !helpers.req(value) || regex?.test(value)
    }
  }

  healthCarePhysician = {
    firstName: {
      required: requiredIf((model) => {
        return model.isSharingEnabled
      }),
      maxLength: maxLength(64)
    },
    lastName: {
      required: requiredIf((model) => {
        return model.isSharingEnabled
      }),
      maxLength: maxLength(64)
    },
    npi: {
      numeric,
      maxLength: maxLength(10),
      minLength: minLength(10),
      validNpi: (value) => validNpi(value)
    },
    practiceName: {
      maxLength: maxLength(64)
    },
    address: {
      required: requiredIf((model) => {
        return model.isSharingEnabled && !model.fax_number
      })
    },
    city: {
      required: requiredIf((model) => {
        return model.isSharingEnabled && !model.fax_number
      })
    },
    state: {
      required: requiredIf((model) => {
        return model.isSharingEnabled && !model.fax_number
      })
    },
    zip: {
      required: requiredIf((model) => {
        return model.isSharingEnabled && !model.fax_number
      })
    },
    faxNumber: {
      required: requiredIf((model) => {
        return model.isSharingEnabled && !(model.address && model.city && model.state && model.zip)
      }),
      validNumber: phone(getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)).validNumber
    },
    directEmailAddress: {
      email
    }
  }
}

import {
  between,
  email as emailValidator,
  required,
  helpers,
  minLength,
  maxLength,
  integer as intValidator,
  decimal as decimalValidator,
  minValue,
  maxValue
} from 'vuelidate/lib/validators'

import { isValidPhoneNumber } from 'libphonenumber-js'
import configManager from '@/config/app-config.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'

export const email = factory({ email: emailValidator })
export const login = factory({
  email: email(),
  password: {
    required
  }
})

const numeric = (numberValidation, min, max, required) => {
  const minValidator = min === undefined ? {} : { minValue: minValue(min) }
  const maxValidator = max === undefined ? {} : { maxValue: maxValue(max) }
  const validationFactory = factory({
    ...numberValidation,
    ...minValidator,
    ...maxValidator,
    required
  })

  return validationFactory()
}
export const integer = (min, max, required) => {
  return numeric({ integer: intValidator }, min, max, required)
}
export const decimal = (min, max, required) => {
  return numeric({ decimal: decimalValidator }, min, max, required)
}

/**
 * Given countryIsoCode, returns phone validator object.
 *
 * @param {string} countryIsoCode
 * @returns {object}
 */
export function phone(countryIsoCode = configManager.companyCountryIsoCode) {
  return factory({
    validNumber: (value) => !helpers.req(value) || isValidPhoneNumber(value, countryIsoCode)
  })()
}

export const month = factory({ between: between(0, 11) })
export const day = factory({ between: between(1, 31) })
export const nextYear = () => year(1)
export const futureYear = () => year(50)
/**
 * Returns validation object based on given inclusive incremented year.
 *
 * @param {number} increment
 * @returns {object}
 */
export function year(increment = 0) {
  const currentYear = new Date().getFullYear()
  return factory({
    between: between(configManager.minSupportedYear, currentYear + increment)
  })()
}

/**
 * Returns validation object for date.
 *
 * excludeDates: exclude dates before MinSupportedYear and past today
 *
 * validation: custom validation function, overrides default withinRange
 * @typedef {{
 *  excludeDates: boolean;
 *  validation: function;
 * }} ValidationConfig
 * @param {ValidationConfig} config
 * @return {object}
 */
export function date({ excludeDates = false, validation } = {}) {
  const validationObj = {
    month: month(),
    day: day(),
    year: year()
  }

  if (excludeDates) {
    validationObj.year.withinRange = (val, vm) => {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const current = new Date()
      current.setHours(0, 0, 0, 0)
      current.setFullYear(vm.year)
      current.setMonth(vm.month)
      current.setDate(vm.day)
      return today >= current
    }
  } else if (validation) {
    validationObj.year.withinRange = validation
  }

  return validationObj
}

export const street = factory({
  minLength: minLength(2),
  maxLength: maxLength(256)
})
export const city = factory({
  minLength: minLength(2),
  maxLength: maxLength(128)
})
/**
 * Given excluding states, returns state validator object.
 *
 * @param {Array<string>} excluded_states
 * @returns {object}
 */
export function state(excluded_states = []) {
  if (excluded_states.length > 0) {
    return factory({
      isAvailable: (value) => !excluded_states.includes(value)
    })()
  }

  return factory()()
}
/**
 * Returns zipcode validator object.
 *
 * address: object with city and state
 *
 * pattern: regex pattern for zipcode
 *
 * validation: custom validation function, overrides default isValid
 *
 * @typedef {{
 *  address: { city: string, state: string }
 *  pattern: RegExp;
 *  validation: Function;
 * }} ZipConfig
 * @param {ZipConfig} config
 * @returns {object}
 */
export function zip({ pattern = configManager.zipPattern, address, validation } = {}) {
  async function isValid(zip, { state, city }) {
    let addressObj
    if (typeof address === 'object') {
      addressObj = { ...address, zip }
    } else {
      addressObj = { state, city, zip }
    }

    if (!addressObj.city || !addressObj.state || !addressObj.zip) {
      return false
    }

    try {
      const { success } = await IXLayerAPI.addressAndZipValidation(addressObj)
      return success
    } catch {
      return false
    }
  }

  const validationObj = factory({
    pattern: (value) => pattern.test(value)
  })()

  if (validation) {
    validationObj.isValid = validation
  } else {
    validationObj.isValid = isValid
  }

  return validationObj
}

/**
 * Factory function to help create validation functions. Given some
 * optional validations, will return validations plus required validator
 * as a function.
 *
 * @param {object} validations
 * @returns {function}
 */
export function factory(validations = {}) {
  return () => ({ required, ...validations })
}

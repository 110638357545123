export const isExternalLink = (link) => {
  try {
    return new URL(link).origin !== window.location.origin
  } catch {
    return false
  }
}

export const isAbsoluteUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

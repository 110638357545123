import { store } from '@/store/index.js'
import { i18n } from '@/plugins/i18n.js'
import { CONFIG } from '@/constants/config.js'
import { useAccessCodeRouting } from '@/use/order/useOrder.js'
import { ORG_BY_ACCESS_CODE } from '@/constants/profile.js'

// patient pre screening flow
const Patient = () => import('@/components/views/Patient/Patient.vue')
const PatientStart = () => import('@/components/views/Patient/PatientStart/PatientStartV3.vue')
const KitSelection = () => import('@/components/views/KitSelectionV3/KitSelectionV3.vue')
const Prescreen = () => import('@/components/views/Main/Prescreen/PrescreenV3.vue')
const PrescreenFinalPage = () =>
  import('@/components/views/Main/PrescreenFinalPageV3/PrescreenFinalPageV3.vue')
const PatientOrderScheduling = () =>
  import(
    '@/components/views/Main/Prescreen/Partials/PrescreenOrderScheduling/PrescreenOrderSchedulingV3.vue'
  )
const Checkout = () => import('@/components/views/Checkout/CheckoutV3.vue')
const PrescreenConsent = () =>
  import('@/components/views/Main/PrescreenConsent/PrescreenConsentV3.vue')

export default {
  path: 'patient',
  component: Patient,
  children: [
    {
      path: ':id?',
      name: 'PatientStart',
      component: PatientStart,
      beforeEnter: async (to, from, next) => {
        if (store.getters.featureFlags.enableSelectATest) {
          return next({ name: 'SelectTest', params: { lang: to.params.lang } })
        }

        const accessCode = store.getters.getConfig(CONFIG.DTC_ORGANIZATION)?.accessCode
        if (!(store.getters.featureFlags.noAccessCodeSignedOutOrder && accessCode)) {
          return next()
        }

        const { success, location, organization } = await useAccessCodeRouting(accessCode)

        if (!success) {
          return next()
        }

        store.commit(ORG_BY_ACCESS_CODE, organization)

        next(location)
      },
      props: true,
      meta: {
        title: i18n.t('headful_title.patient_start'),
        isFooterHidden: true
      }
    },
    {
      path: 'patient-kit-selection/:accessCode/:orgId',
      name: 'PatientKitSelection',
      component: KitSelection,
      beforeEnter: async (to, from, next) => {
        if (store.getters.featureFlags.enableSelectATest) {
          return next({ name: 'SelectTest', params: { lang: to.params.lang } })
        }

        return next()
      },
      props: true,
      meta: {
        title: i18n.t('headful_title.kit_selection'),
        isFooterHidden: true
      }
    },
    {
      path: 'patient-consent/:orgId/:productId/:eligibilityResponseUuid',
      name: 'PatientPrescreenConsent',
      component: PrescreenConsent,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen_consent'),
        isFooterHidden: true
      }
    },
    {
      path: 'patient-prescreen/:accessCode/:orgId/:productId/:eligibilityResponseUuid?',
      name: 'PatientPrescreen',
      component: Prescreen,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen'),
        isFooterHidden: true
      }
    },
    {
      path: 'patient-result/:orgId/:productId/:productStatusId/:orderId?',
      name: 'PatientFinal',
      component: PrescreenFinalPage,
      props: true,
      meta: {
        title: i18n.t('headful_title.prescreen_result'),
        isFooterHidden: true,
        sendTo: 'default'
      }
    },
    {
      path: 'patient-order-scheduling/:accessCode/:orgId/:productId/:productStatusId?/:orderId?/:isPostOrdering?/:appointmentId?',
      name: 'PatientOrderScheduling',
      component: PatientOrderScheduling,
      props: true,
      meta: {
        title: i18n.t('headful_title.order_scheduling'),
        isFooterHidden: true
      }
    },
    {
      path: 'patient-checkout/:accessCode/:orgId/:productId/:productStatusId/:orderId?',
      name: 'PatientCheckout',
      component: Checkout,
      props: true,
      meta: {
        title: i18n.t('headful_title.checkout'),
        isFooterHidden: true
      }
    }
  ]
}

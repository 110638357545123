import { i18n } from '@/plugins/i18n.js'
import { store } from '@/store/index.js'

// signed in prescreening flow
const KitSelection = () => import('@/components/views/KitSelectionV3/KitSelectionV3.vue')
const Prescreen = () => import('@/components/views/Main/Prescreen/PrescreenV3.vue')
const PrescreenConsent = () =>
  import('@/components/views/Main/PrescreenConsent/PrescreenConsentV3.vue')
const PrescreenOrderScheduling = () =>
  import(
    '@/components/views/Main/Prescreen/Partials/PrescreenOrderScheduling/PrescreenOrderSchedulingV3.vue'
  )
const Checkout = () => import('@/components/views/Checkout/CheckoutV3.vue')
const Failure = () => import('@/components/views/Checkout/Failure/FailureV3.vue')
const PrescreenCheckoutSuccess = () => import('@/components/views/Checkout/Success/Success.vue')
const PrescreenFinalPage = () =>
  import('@/components/views/Main/PrescreenFinalPageV3/PrescreenFinalPageV3.vue')

export default [
  {
    path: 'prescreen-kit-selection/:orgId',
    name: 'PrescreenKitSelection',
    component: KitSelection,
    beforeEnter: async (to, from, next) => {
      if (store.getters.featureFlags.enableSelectATest) {
        return next({ name: 'SelectTest', params: { lang: to.params.lang } })
      }

      return next()
    },
    props: true,
    meta: {
      title: i18n.t('headful_title.kit_selection'),
      requiresAuth: true,
      showOrderWizardHeader: true
    }
  },
  {
    path: 'prescreen-consent/:orgId/:productId/:eligibilityResponseUuid?',
    name: 'PrescreenConsent',
    component: PrescreenConsent,
    props: true,
    meta: {
      title: i18n.t('headful_title.prescreen_consent'),
      requiresAuth: true,
      showOrderWizardHeader: true,
      isFooterHidden: true
    }
  },
  {
    path: 'prescreen/:orgId/:productId/:eligibilityResponseUuid?',
    name: 'Prescreen',
    component: Prescreen,
    props: true,
    meta: {
      title: i18n.t('headful_title.prescreen'),
      isFooterHidden: true,
      requiresAuth: true,
      showOrderWizardHeader: true
    }
  },
  {
    path: 'prescreen-order-scheduling/:orgId/:productId/:productStatusId?/:orderId?/:isPostOrdering?/:appointmentId?',
    name: 'PrescreenOrderScheduling',
    component: PrescreenOrderScheduling,
    props: true,
    meta: {
      title: i18n.t('headful_title.order_scheduling'),
      isFooterHidden: true,
      requiresAuth: true,
      showOrderWizardHeader: true
    }
  },
  {
    path: 'prescreen-checkout/:orgId/:productId/:productStatusId/:orderId/:eligibilityResponseUuid?',
    name: 'PrescreenCheckout',
    component: Checkout,
    props: true,
    meta: {
      title: i18n.t('headful_title.checkout'),
      isFooterHidden: true,
      requiresAuth: true,
      showOrderWizardHeader: true
    }
  },
  {
    path: 'prescreen-failure',
    name: 'PrescreenCheckoutFailure',
    component: Failure,
    meta: {
      title: i18n.t('headful_title.prescreen_failure'),
      requiresAuth: true
    }
  },
  {
    path: 'prescreen-success',
    name: 'PrescreenCheckoutSuccess',
    component: PrescreenCheckoutSuccess,
    meta: {
      title: i18n.t('headful_title.payment_redirect'),
      requiresAuth: true,
      showOrderWizardHeader: true,
      sendTo: 'default'
    }
  },
  {
    path: 'prescreen-result/:orgId/:productId/:productStatusId/',
    name: 'PrescreenFinal',
    component: PrescreenFinalPage,
    props: true,
    meta: {
      title: i18n.t('headful_title.prescreen_result'),
      requiresAuth: true,
      showOrderWizardHeader: true,
      sendTo: 'default'
    }
  }
]
